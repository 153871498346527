import React, {Fragment, useEffect, useState} from 'react'
import {t} from 'i18next'
import { FormGroup, Label, Input, Button, Card, CardBody, CardFooter } from 'reactstrap'
import { useRecoilValue } from 'recoil'
import Modal from 'react-modal';

import { isNull, modalStyles, areArraysEqual } from '../../izUtils'
import { multiSelectLoading } from '../../recoil/recoil'
import Loader from '../spinner/Loader'
import DisplayHistory from '../../helpers/DisplayHistory'
import { checkHistory } from '../../helpers/checkHistory';

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const MultiSelect = ({data, onChange, showErrors, component, history}) => {
    const { name, display, errors, title, values, disabled, mandatory} = data;

    const multiLoading = useRecoilValue(multiSelectLoading);
    const [value, setValue] = useState([]);
    const [modalSSC, setModalSSC] = useState(false);
    const [selectedSecuritySystem, setSelectedSecuritySystem] = useState(null);

    useEffect(() => {
        setValue(isNull(data.value) ? [] : [...data.value])

    }, [data.value])

    const handleChange = (optionId) => {
        if (value.indexOf(optionId) === -1) {
            setValue(prev => [...prev, optionId])
        } else {
            setValue(prev => prev.filter(el => el !== optionId))
        }

        onChange(optionId, name, component);
    }

    const handleModalButton = (type, option) => {
        if (type === 'ssc') {
            if (!isNull(option)) {
                setSelectedSecuritySystem(option.security_system_components)
                setModalSSC(true)
            } else {
                setSelectedSecuritySystem(null)
                setModalSSC(false)
            }
        }
    }

    let showModals = false;
    if (['security_systems'].indexOf(name) !== -1) showModals = true;

    if (display) {
        let lsInputs = JSON.parse(localStorage.getItem('AKODA.taskInputs'));

        let hasHistory = false;
        if (!isNull(history)) {
            hasHistory = checkHistory(data, history)
        }

        return (
            <FormGroup style={{ position: 'relative' }} className={hasHistory ? 'red-alert' : ''} >
                {(multiLoading === name+'-'+component?.id) && <Loader />}
                <Label>{title + (mandatory ? " *" : "")}</Label>
                {(!isNull(values) && values.length !== 0) ?
                    <div className="custom-control custom-checkbox form-check display-input-security-systems" data-name={name}>
                        {values.map((option, index) => (
                            <div className="form-checkbox-group d-flex flex-wrap flex-lg-nowrap justify-content-between py-1" key={data.name+'_'+index+'_'+option.title}>
                                <div className='me-4'>
                                    <Input
                                        className="custom-control-input"
                                        id={option.id + (isNull(component) ? '' : '-' + component.id)}
                                        type={"checkbox"}
                                        onChange={() => handleChange(option.id)}
                                        checked={value.indexOf(option.id) !== -1}
                                        disabled={disabled || (multiLoading === name+'-'+component?.id)}
                                    />
                                    <Label className="checkmark" htmlFor={option.id + (isNull(component) ? '' : '-' + component.id)}></Label>
                                    <label className="custom-control-label" htmlFor={option.id + (isNull(component) ? '' : '-' + component.id)}>
                                        {option.title}
                                    </label>
                                </div>
                                {showModals &&
                                    <div className='d-flex flex-wrap flex-lg-nowrap justify-content-between'>
                                        <div className='me-2'>
                                            {!isNull(option.active_contract) &&
                                                (option.active_contract ?
                                                    <span className="badge bg-success">{t("isContract")}</span>
                                                    :
                                                    <span className="badge bg-danger">{t("noContract")}</span>
                                                )
                                            }
                                        </div>
                                        {(!isNull(lsInputs) && !isNull(lsInputs.service_type) && lsInputs.service_type.value === 'flat_rate') &&
                                            <Fragment>
                                                {!isNull(option.security_system_inspections) &&
                                                   <div className='me-3'>
                                                        <b>{option.security_system_inspections.total_inspections + "/" + option.security_system_inspections.system_checks_number}</b>
                                                    </div>
                                                }
                                                {(!isNull(option.check_notes) && option.check_notes.length !== 0) &&
                                                    <div className='me-3' style={{ color: 'red' }}>
                                                        {t('form.label.checkNotes')}:&nbsp;<b>{option.check_notes}</b>
                                                    </div>
                                                }
                                            </Fragment>
                                        }
                                        {(!isNull(option.security_system_components) && option.security_system_components.length !== 0) &&
                                            <div>
                                                <Button color="primary" className="btn-outline-primary btn-sm py-1 px-2" onClick={() => handleModalButton('ssc', option)}>{t('showSystems')}</Button>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        ))}
                    </div>
                    :
                    <div className="form-checkbox-group mb-1 ms-1">/</div>
                }
                {!isNull(showErrors) && showErrors && !isNull(errors) && errors.length !== 0 && <div style={{ color: "red", marginBottom: "10px" }}>{errors.map((error, index) => <div key={"error-"+index}>{error}</div>)}</div>}
                {hasHistory && <DisplayHistory history={history} />}
                {showModals &&
                    <Fragment>
                        <Modal
                            isOpen={modalSSC}
                            onRequestClose={() => handleModalButton('ssc', null)}
                            style={modalStyles}
                            contentLabel={"Security System Component Modal"}
                        >
                            <Card className="ribbon-wrapper" style={{ marginBottom: '0px' }}>
                                <CardBody>
                                    <div className="ribbon ribbon-clip ribbon-primary">{t('form.label.securitySystemComponents')}</div>
                                    {!isNull(selectedSecuritySystem) &&
                                        <ul>
                                            {selectedSecuritySystem.map(system => (
                                                <li key={"security_system_components_"+system.id}>
                                                    {
                                                        (!isNull(system.manufacturer) ? system.manufacturer.title : '') +
                                                        (!isNull(system.system) ? ' - ' + system.system.title : '') +
                                                        (!isNull(system.central_station) ? ' - ' + system.central_station.title : '') +
                                                        ((!isNull(system.component_notes) && system.component_notes.length !== 0) ? ' (' + system.component_notes + ' ) ': '')
                                                    }
                                                </li>
                                            ))}
                                        </ul>
                                    }
                                </CardBody>
                                <CardFooter>
                                    <div className='text-start'>
                                        <Button className='primary' onClick={() => handleModalButton('ssc', null)}>{t('close')}</Button>
                                    </div>
                                </CardFooter>
                            </Card>
                        </Modal>
                    </Fragment>
                }
            </FormGroup>
        )
    } else {
        return null;
    }
}

export default MultiSelect
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import { useRecoilValue } from 'recoil'
import { Form, Card, CardBody, Row, Col } from 'reactstrap'

import { isNull } from '../../../izUtils'
import { getSingleItem } from '../../../helpers/getSingleItem'
import { headersState } from '../../../recoil/recoil'
import Spinner from '../../spinner/Spinner'
import DisplayFields from '../../../helpers/displayFields'
import FileUpload from '../../fileUpload/FileUpload'

const ProjectLog = ({index, log}) => {
    const navigate = useNavigate();

    const headers = useRecoilValue(headersState);
    const [inputs, setInputs] = useState(null)

    const lsProjectLogsFiles = 'AKODA.projectLogsFiles-'+log.id;

    useEffect(() => {
        getSingleItem(headers, log.id, '/api/project/log/', navigate).then(data => {
            if (!isNull(data)) {
                setInputs(data.fields);
            }
        })

        return () => {
            localStorage.removeItem(lsProjectLogsFiles)
        }
    }, []) // eslint-disable-line

    // Create fields
    const mapLinker = (field) => {
        if (!isNull(inputs[field])) return <DisplayFields key={'display-'+inputs[field].name} data={inputs[field]} />
    }

    if (isNull(inputs)) return <Spinner />
    return (
        <Card className="ribbon-wrapper">
            <CardBody>
                <div className="ribbon ribbon-clip ribbon-primary">{t('projects.logs.title', { number: (index+1) })}</div>
                <Form className="theme-form">
                    <Row>
                        <Col sm="12" md="6">
                            { ['client', 'facility', 'project_name', 'security_system', 'main_technician', 'worklog_from', 'worklog_to', 'log_materials', 'work_description', 'requires_review'].map(field => mapLinker(field)) }
                        </Col>
                        <Col sm="12" md="6">
                            <FileUpload data={inputs.files} apiUrl="/api/project/log/file/" saveToLS={lsProjectLogsFiles}   inputType="viewProjectsLog" />
                        </Col>
                    </Row>
                </Form>
            </CardBody>
        </Card>
    )
}

export default ProjectLog